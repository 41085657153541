@tailwind base;
@tailwind components;
@tailwind utilities;

.form {
  height: auto;
  background-size: auto;

  display: flex;

  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
}

.container {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: justify;
  justify-content: justify;
}

input {
  padding-bottom: 5px;
  margin-bottom: 2px;
}

.submit {
  border-radius: 10px;
  text-align: center;
  background-color: #1a1aff;
  color: aliceblue;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  width: 100px;
  border: none;
  transform: all 0.7s;
}

.submit:hover {
  background-color: rgb(0, 183, 255);
  cursor: pointer;
}

.cpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cp {
  background-color: #1a1aff;
  border-radius: 10px;
  border: none;
  color: white;
  height: 3rem;
  width: 140px;
  margin: 10px 10px;
  transition: all 0.7s;
}

.cp:hover {
  background-color: rgb(0, 183, 255);
  cursor: pointer;
}

/* .overlay-image{
  width: 100%;
  height: 100%;
} */

.overlay-image {
  position: absolute;
  top: 60%;
  left: 92%;

  transform: translate(-90%, -55%) scale(0.2);
  /* width: '10%';
  height: undefined;
  aspect-ratio: 1; */
  opacity: 0.6;
}

/* .overlay-container {
  position: absolute;
  width: 34%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
} */
/* .webcam-container {
  position: relative;
} */

#video-container {
  position: relative;
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.webcam-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay-container {
  position: absolute;
  width: 50%;
  height: 50%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* top: 350px;
left: 1070px; */

@media screen and (max-width: 900px) {
  /* Add CSS rules for screens below 900px here */
  .react-webcam {
    width: 300px;
    /* Change the width to 100% or your desired value */
  }

  .heading {
    font-size: medium;
  }

  /* .overlay-image {
    position: absolute;
   
    transform: scale(0.1);
    opacity: 0.6;
  } */
  /* .webcam-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */
}

.all {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80vw;
}

.select {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  /* Remove the default button border */
  background: none;
  /* Remove the default button background */
  cursor: pointer;
  /* Show a pointer cursor when hovering */

  /* Define the width and height of the button */
  width: 20vw;
  height: 5vw;
  margin: 0.5vw;
  /* transition: all 0.7s; */
}

.select:hover {
  transform: scale(1.05);
  background-color: rgb(233, 223, 223);
}

.select img {
  /* Make the image expand to fill the button */
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Preserve the aspect ratio of the image */
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-left {
  margin-left: 20px;
  height: 500px;
  width: 500px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hold {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.09);
}

.about-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.right1 {
  height: 300px;

  width: 500px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-bottom: 5px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(194, 194, 202, 0.4);
}

.oralpic {
  max-width: 50%;
  height: auto;
  padding: 0;
  margin: 0;
}

.score {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.results {
  width: 10%; /* Adjust the width as needed */
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border-radius: 50%; /* Make the div round */
  background-color: #f0f0f0; /* Set background color */
  margin: auto; /* Center the div horizontally */
  margin-top: 20px; /* Add some top margin for spacing */
  text-align: center; /* Center the text inside the div */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.rept {
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  box-shadow: 1px 1px 5px rgb(152, 150, 150);
}

.info {
  display: flex;
}

.left2 {
  padding: 10px 10px;
  margin: 10px 10px;
}

.right2 {
  padding: 10px 10px;
  margin: 10px 10px;
}

.resulta {
  display: flex;
}

.left3 {
  padding: 10px 10px;
  margin: 10px 10px;
}

.right3 {
  padding: 10px 10px;
  margin: 10px 10px;
}

.footer1 {
  width: 100%;
  
 
  padding: 0;
  margin: 0;
  
}

.footerimage {
  width: 110%;
  background-size: cover;
}

@media (max-width: 600px) {
  .rept {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.table1{
  margin-left:200px;
}

@media (max-width: 600px) {
  .table1 {
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .right3 {
    margin-left: 0px;
  }
}

.circle {
  border-radius: 50%;
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 40px;
  padding-bottom: 40px;

  margin: auto;
}

.two{
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (max-width: 600px) {
 .circle{
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
 }
 .two{
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;

 }
}
