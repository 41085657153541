.footer {
    padding: 20px 0;
    color: #fff;
  }
  
  .footer h4 {
    font-size: 18px;
    font-weight: bold;
  }
  
  .footer p {
    margin-bottom: 0;
    font-size: 14px;

  }
  
  .footer-sentence {
    text-align: justify;
    font-size: 14px;

  }
  
  /* Responsive styles */
  @media (max-width: 767px) {
    .email-contact {
      margin-bottom: 20px;
    }
  }
  